<template>
    <div>
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-header">
                    <h4 class="card-title">
                        <span>Cestas Vapza</span>
                    </h4>
                    <b-col v-if="showFiltro ? false : true">
                        <div style="float:right; z-index: 3;">
                            <div class="DivBotaoLadoLado">
                                <b-button v-b-toggle="'collapse-filtro'" variant="primary">
                                    <span>FILTROS</span>
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </div>
                <b-collapse visible id="collapse-filtro">
                    <div class="card-body">
                        <div>
                            <b-row style="gap: 2rem;">
                                <b-col md="6" xl="2" class="mb-1">
                                    <b-form-group label="Filtrar Por:">
                                        <b-form-select v-model="FiltroTela.BuscarPor" :options="BuscarPorOptions">
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6" xl="4" class="mb-1">
                                    <b-form-group label="Valor:">
                                        <b-form-input @change="Buscar" placeholder="" v-model="FiltroTela.QRCODCOLAB" />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="DivBotaoLadoLado">
                                <b-button variant="danger" type="button"
                                    @click="$router.push('../Home')"><span>RETORNAR</span>
                                </b-button>
                            </div>
                            <div class="DivBotaoLadoLado">
                                <b-button v-b-toggle="'collapse-filtro'" variant="primary" type="submit"
                                    @click="Buscar(this.FiltroTela.QRCODCOLAB)"><span>CONSULTAR</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div class="col-12">
            <div class="card mb-4">
                <div class="card-body pb-60">
                    <div>
                        <b-table :items="ListaFunc" :fields="ListaFuncfields" responsive="sm" select-mode="single"
                            striped hover selectable sticky-header>
                            <template #cell(show_details)="row">
                                <b-button id="btnBaixa" ref="btnBaixa" size="sm" variant="success"
                                    @click="DarBaixa([row.item.CODFUNC],
                                        [row.item.CODEMP],
                                        [row.item.AD_PRODVAPZA],
                                        [row.item.AD_CHAVEPONTO])" class="mr-2 btnBaixa">
                                    OK
                                </b-button>
                            </template>

                            <template #cell(Codfunc)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(PRODUTOSVAPZA)="row">
                                <b ref="LinhaProdVapza">{{ row.value }}</b>
                            </template>

                            <template #cell(Retirada)="row">
                                {{ row.value }}
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import BCardCode from '@core/components/b-card-code'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import {
    BFormGroup,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadio,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    VBToggle,
} from 'bootstrap-vue'
import { numeric } from 'vee-validate/dist/rules'
import { VueSelect } from 'vue-select'
import moment, { months } from 'moment'
import { splice } from 'postcss-rtl/lib/affected-props'
import { log } from 'util'
import { isNull } from 'util'


export default {
    components: {
        VueApexCharts,
        Multiselect,
        BFormGroup,
        BFormDatepicker,
        BFormRadio,
        BRow,
        BCol,
        BButton,
        BTable,
        BFormSelect,
        BFormInput,
        BCollapse,
        BCard,
    },
    directives: { 'b-toggle': VBToggle },
    data() {
        return {
            showFiltro: true,
            ATIVABTN: false,
            FiltroTela: {
                QRCODCOLAB: "",
                BuscarPor: 'QRCODCOLAB'
            },
            BuscarPorOptions: [
                { value: 'QRCODCOLAB', text: 'Código' },
                { value: 'QRCODCOLAB', text: 'CPF' },

            ],

            ListaFunc: [
                { CODFUNC: "" },
                { NOMEFUNC: "" },
                { RETIRADA: "" },
                { PRODUTOSVAPZA: "" },
                { CODEMP: "" },
                { AD_PRODVAPZA: "" },
                { msg: "" },
                { AD_CHAVEPONTO: "" },
            ],
            ListaFuncfields: [
                { key: 'show_details', label: 'Validar', sortable: false },
                { key: 'CODFUNC', label: 'N° Funcionário', sortable: true, tdClass: 'td100' },
                { key: 'NOMEFUNC', label: 'Funcionário', sortable: true, tdClass: 'td100' },
                { key: 'DESCRCENCUS', label: 'Cent Resultado', sortable: true, tdClass: 'td100' },
                { key: 'PRODUTOSVAPZA', label: 'Produtos Vapza', sortable: true, tdClass: 'td100' },
                { key: 'RETIRADA', label: 'Última Retirada', sortable: true, tdClass: 'td100' },
                { key: 'CODEMP', label: 'CODEMP', sortable: true, tdClass: 'td100' }.hide,
                { key: 'AD_PRODVAPZA', label: 'AD_PRODVAPZA', sortable: true, tdClass: 'td100' }.hide,
                { key: 'AD_CHAVEPONTO', label: 'AD_CHAVEPONTO', sortable: true, tdClass: 'td100' }.hide,
            ],
        }
    },
    methods: {
        async MostraReport() {
            let notification = ""
            Spinner.StarLoad()
            const usuario = this.$session.get('usuario')

            let ParametroJson = ""
            let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if (response.data != null) {
                        this.RelName = response.data.descricaoRelatorio
                    } else {
                        notification = ConexaoAPI.MsgFiltroSemRetorno()
                        AlertaMsg.FormataMsg(notification)
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })
            if (notification === '') {
                Spinner.EndLoad()
            }
        },
        async Buscar(qrCode) {
            Spinner.StarLoad()
            this.showFiltro = false

            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.ListaFunc = []

            if (this.NRCOLAB != "" || this.QRCODCOLAB != "") {
                this.ATIVABTN = true

            }

            URLpost = `/Cesta/BuscarCesta?CODUSU=${usuario.codusu}&_qrCode=${qrCode}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if (response.data.length > 0) {
                        this.ListaFunc = response.data
                        this.FiltroTela.QRCODCOLAB = null
                        if (this.ListaFunc[0].msg == null) {
                            this.ListaFunc[0].msg = ''
                        }
                    }
                }
            }).catch(error => {
                this.FiltroTela.QRCODCOLAB = null
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })
            if (notification === '') {
                setTimeout(() => {
                    Spinner.EndLoad();
                }, 1000);
            }
            if (this.ListaFunc[0].PRODUTOSVAPZA == "Liberado" || this.ListaFunc[0].PRODUTOSVAPZA == "Liberado (RH)") {
                this.$refs.LinhaProdVapza.style.color = '#28c76f';
            } else {
                this.$refs.LinhaProdVapza.style.color = '#ffcc01';
            }
            if (this.ListaFunc[0].msg != '') {
                this.$refs.btnBaixa.style.display = "none";
            }
            if (this.ListaFunc[0].msg != '') {
                notification = { message: this.ListaFunc[0].msg, alert_type: 'error' }
                AlertaMsg.FormataMsg(notification)
            }
        },
        async DarBaixa(codfunc, codemp, AD_PRODVAPZA, AD_CHAVEPONTO) {
            if (this.ATIVABTN == true) {
                Spinner.StarLoad()

                const usuario = this.$session.get('usuario')
                let notification = ""

                let ParametroJson = ''
                let URLpost = ''

                this.ListaFunc = []

                URLpost = `/Cesta/InserirCestaRetirada?CODUSU=${usuario.codusu}&_codFunc=${codfunc}&_codEmp=${codemp}&_adProdVapza=${AD_PRODVAPZA}`

                await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                    if (response.status === 200) {
                        this.Buscar(AD_CHAVEPONTO);
                        this.ListaFunc = response.data
                    }
                }).catch(error => {
                    notification = ConexaoAPI.ValidaErro(error)
                    AlertaMsg.FormataMsg(notification)
                })
                if (notification === '') {
                    setTimeout(() => {
                        Spinner.EndLoad();
                    }, 1000);
                }
                if (this.ListaFunc[0].PRODUTOSVAPZA != "Liberado" || this.ListaFunc[0].PRODUTOSVAPZA != "Liberado (RH)") {
                    this.$refs.LinhaProdVapza.style.color = '#ffcc01';
                }
                if (this.ListaFunc[0].msg != '') {
                    this.$refs.btnBaixa.style.display = "none";
                }
            }
        },
    },
    mounted() {
        if (this.$route.name != null) {
            this.RelRota = this.$route.name
            this.MostraReport()
            this.ListaFunc = []
        }
    },

}
</script>